




var burger = document.querySelector(".burger");
var menu = document.querySelector(".link");
var overlay = document.querySelector('.overlay');


var pagecontent = document.querySelector('.overlay2');
var pagecontent1 = document.querySelector('.overlay3');

var contactbutton = document.querySelectorAll(".con");
var contactbutton1 = document.querySelectorAll(".con2");

var croix = document.querySelector(".croix");
var croixx = document.querySelector(".croixx");


burger.addEventListener('click', function () {
    if (burger.classList.contains("burger--open")) {
        burger.classList.toggle("burger--close");
    } else {
        burger.classList.add("burger--open");
    }

    overlay.classList.toggle('open');
});
menu.addEventListener('click', function () {
    overlay.classList.toggle('open');
    burger.classList.remove("burger--open");
});


// Page contact


for (let contactbuttons of contactbutton) {

contactbuttons.addEventListener('click', function () {
  pagecontent.classList.toggle('open');
});
}

croix.addEventListener('click', function () {
  pagecontent.classList.toggle('open');
});


for (let contactbuttonss of contactbutton1) {

contactbuttonss.addEventListener('click', function () {
  pagecontent1.classList.toggle('open');
});
}

croixx.addEventListener('click', function () {
    pagecontent1.classList.toggle('open');
});







//jQuery time
var current_fs, next_fs, previous_fs; //fieldsets
var left, opacity, scale; //fieldset properties which we will animate
var animating; //flag to prevent quick multi-click glitches

$(".next").click(function(){
	if(animating) return false;
	animating = true;
	
	current_fs = $(this).parent();
	next_fs = $(this).parent().next();
	
	//activate next step on progressbar using the index of next_fs
	$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
	
	//show the next fieldset
	next_fs.show(); 
	//hide the current fieldset with style
	current_fs.animate({opacity: 0}, {
		step: function(now, mx) {
			//as the opacity of current_fs reduces to 0 - stored in "now"
			//1. scale current_fs down to 80%
			scale = 1 - (1 - now) * 0.2;
			//2. bring next_fs from the right(50%)
			left = (now * 50)+"%";
			//3. increase opacity of next_fs to 1 as it moves in
			opacity = 1 - now;
			current_fs.css({
        'transform': 'scale('+scale+')',
        'position': 'absolute'
      });
			next_fs.css({'left': left, 'opacity': opacity});
		}, 
		duration: 800, 
		complete: function(){
			current_fs.hide();
			animating = false;
		}, 
		//this comes from the custom easing plugin
		easing: 'easeInOutBack'
	});
});

$(".previous").click(function(){
	if(animating) return false;
	animating = true;
	
	current_fs = $(this).parent();
	previous_fs = $(this).parent().prev();
	
	//de-activate current step on progressbar
	$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
	
	//show the previous fieldset
	previous_fs.show(); 
	//hide the current fieldset with style
	current_fs.animate({opacity: 0}, {
		step: function(now, mx) {
			//as the opacity of current_fs reduces to 0 - stored in "now"
			//1. scale previous_fs from 80% to 100%
			scale = 0.8 + (1 - now) * 0.2;
			//2. take current_fs to the right(50%) - from 0%
			left = ((1-now) * 50)+"%";
			//3. increase opacity of previous_fs to 1 as it moves in
			opacity = 1 - now;
			current_fs.css({'left': left});
			previous_fs.css({'transform': 'scale('+scale+')', 'opacity': opacity});
		}, 
		duration: 800, 
		complete: function(){
			animating = false;
		}, 
		//this comes from the custom easing plugin
		easing: 'easeInOutBack'
	});
});


